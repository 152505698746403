<template>
  <v-container>
    <app-error :error="error" v-if="error" />
    <app-loader large v-if="!organization"></app-loader>
    <div v-else>
      <v-card-title>
        <v-icon class="mr-3">business</v-icon>
        {{ organization.name }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-card>
              <v-card-title>{{ $t("contacts.title") }}</v-card-title>
              <v-card-text v-if="contacts">
                <contact-card
                  v-for="contact in contacts"
                  :key="contact.uid"
                  :contact="contact"
                ></contact-card>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-card>
              <v-card-title>{{ $t("locations.title") }}</v-card-title>
              <v-card-text v-if="locations">
                <location-list :locations="locations" />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-card>
              <v-card-title>{{ $t("serviceProviders.title") }}</v-card-title>
              <v-card-text v-if="serviceProviders">
                <service-provider-list :service-providers="serviceProviders" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-container>
</template>

<script lang="js">
import ContactCard from '@/components/contacts/ContactCard';
import LocationList from '@/components/locations/LocationList';
import ServiceProviderList from '@/components/service_providers/ServiceProviderList';

export default {
  props: {
    uid: { type: String, required: true }
  },
  data() {
    return {
      organization: {},
      contacts: undefined,
      locations: undefined,
      serviceProviders: undefined,
      loading: false,
      error: null
    }
  },
  created() {
    this.$store
        .dispatch("organizations/get", this.uid)
        .then(c => (this.organization = c))
        .catch(err => (this.error = err));

    this.$store
        .dispatch("organizations/contacts", this.uid)
        .then(c => this.contacts = c)
        .catch(err => this.error = err);

    this.$store
        .dispatch("organizations/locations", this.uid)
        .then(c => this.locations = c)
        .catch(err => this.error = err);

    this.$store
        .dispatch("organizations/serviceProviders", this.uid)
        .then(c => this.serviceProviders = c)
        .catch(err => this.error = err);
  },
  components: {
    ContactCard,
    LocationList,
    ServiceProviderList
  }
}
</script>
