var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-breadcrumbs',{attrs:{"items":[
      {
        text: _vm.$t('organizations.title'),
        disabled: true
      }
    ]}}),(_vm.error)?_c('app-error',{attrs:{"error":_vm.error}}):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.$t("organizations.title")))]),_c('v-divider',{staticClass:"mb-2"}),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-icon":"filter_list","label":_vm.$t('organizations.filter'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'organizations/create' },"color":"secondary","fab":"","small":""}},[_c('v-icon',[_vm._v("add")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000]
    },"headers":_vm.headers,"items":_vm.organizations,"loading":_vm.loading,"fixed-header":"","item-key":"uid","multi-sort":"","search":_vm.filter},on:{"click:row":function($event){return _vm.$router.push({
        name: 'organizations/details',
        params: { uid: $event.uid }
      })}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"to":{ name: 'organizations/edit', params: { uid: item.uid } },"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }