<template>
  <v-list>
    <v-list-item
      v-for="serviceProvider of serviceProviders"
      :key="serviceProvider.uid"
    >
      <v-list-item-content>{{ serviceProvider.title }}</v-list-item-content>
      <v-list-item-action>
        <v-btn
          fab
          icon
          :to="{
            name: 'serviceproviders/details',
            params: { uid: serviceProvider.uid }
          }"
        >
          <v-icon>folder</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script lang="js">

export default {
  props: {
    serviceProviders: { required: true, type: Array}
  },
  data() {
    return {

    }
  }
}
</script>
