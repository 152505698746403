<template>
  <v-container>
    <app-error :error="error" v-if="error" />
    <organization-editor
      :title="$t('organizations.titleCreate')"
      v-model="organization"
      @submit="create($event)"
      @cancel="cancel($event)"
    />
  </v-container>
</template>

<script>
import OrganizationEditor from "@/components/organizations/OrganizationEditor";

export default {
  data() {
    return {
      organization: {},
      error: null
    };
  },
  components: {
    OrganizationEditor
  },
  methods: {
    create(organization) {
      this.error = null;
      this.$store
        .dispatch("organizations/create", organization)
        .then(() => {
          this.$router.replace({
            name: "organizations/list"
          });
        })
        .catch(err => (this.error = err));
    }
  }
};
</script>

<style></style>
