<template>
  <v-list>
    <v-list-item v-for="location of locations" :key="location.uid">
      <v-list-item-content>{{ location.title }}</v-list-item-content>
      <v-list-item-action>
        <v-btn
          fab
          icon
          :to="{ name: 'locations/details', params: { uid: location.uid } }"
        >
          <v-icon>folder</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script lang="js">

export default {
  props: {
    locations: { required: true, type: Array}
  },
  data() {
    return {

    }
  }
}
</script>
