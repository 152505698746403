<template>
  <v-container>
    <v-breadcrumbs
      :items="[
        {
          text: $t('organizations.title'),
          disabled: true
        }
      ]"
    />
    <app-error v-if="error" :error="error" />
    <h1>{{ $t("organizations.title") }}</h1>
    <v-divider class="mb-2" />

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="filter"
          prepend-icon="filter_list"
          :label="$t('organizations.filter')"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          :to="{ name: 'organizations/create' }"
          color="secondary"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-data-table
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000]
      }"
      :headers="headers"
      :items="organizations"
      :loading="loading"
      class="elevation-1"
      fixed-header
      item-key="uid"
      multi-sort
      :search="filter"
      @click:row="
        $router.push({
          name: 'organizations/details',
          params: { uid: $event.uid }
        })
      "
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          :to="{ name: 'organizations/edit', params: { uid: item.uid } }"
          icon
          small
        >
          <v-icon small>
            edit
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      organizations: [],
      filter: null,
      error: null,
      loading: false,
      headers: [
        { text: this.$t("organizations.name"), value: "name" },
        { text: "", value: "actions", sortable: false, width: 24 }
      ]
    };
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      this.loading = true;
      this.error = null;

      this.$store
        .dispatch("organizations/list")
        .then(o => {
          if (o) {
            this.organizations = o;
          } else {
            this.organizations = [];
          }
        })
        .catch(err => {
          this.error = err;
          this.organizations = [];
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
