<template>
  <ValidationObserver slim v-slot="{ dirty }" ref="observer">
    <v-card>
      <v-card-title class="headline" v-if="title">
        {{ title }}
      </v-card-title>
      <v-divider v-if="title" />
      <v-card-text>
        <app-error :error="error" v-if="error" />

        <v-form @submit.prevent="submit()">
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :name="$t('organizations.name')"
                >
                  <v-text-field
                    prepend-icon="business"
                    v-model.trim="organization.name"
                    :label="$t('organizations.name')"
                    :error-messages="errors"
                    required
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit()"
          fab
          color="secondary"
          :disabled="!dirty"
          v-if="!readonly"
        >
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn fab @click="cancel()">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    value: { type: Object, required: true },
    title: { type: String, required: false },
    readonly: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      organization: JSON.parse(JSON.stringify(this.value)), //do not muate value
      error: null
    };
  },
  watch: {
    value() {
      this.organization = JSON.parse(JSON.stringify(this.value));
    }
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.$emit("submit", this.organization);
        }
      });
    },
    cancel() {
      if (this.$refs.observer.flags.dirty) {
        this.$root
          .$confirm(this.$t("app.discard"), this.$t("app.discardChanges") + "?")
          .then(confirm => {
            if (confirm) {
              this.$router.back();
            }
          });
      } else {
        this.$router.back();
      }
    }
  }
};
</script>

<style></style>
