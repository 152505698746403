<template>
  <v-container>
    <app-error :error="error" v-if="error" />
    <app-loader large v-if="!organization"></app-loader>
    <organization-editor
      v-model="organization"
      v-else
      @submit="update($event)"
    />
  </v-container>
</template>

<script>
import OrganizationEditor from "@/components/organizations/OrganizationEditor";

export default {
  props: {
    uid: { type: String, required: true }
  },
  data() {
    return {
      organization: null,
      error: null,
      breadcrumbs: []
    };
  },
  components: {
    OrganizationEditor
  },
  created() {
    this.$store
      .dispatch("organizations/get", this.uid)
      .then(c => (this.organization = c))
      .catch(err => (this.error = err));
  },
  methods: {
    update(organization) {
      this.error = null;
      this.$store
        .dispatch("organizations/update", organization)
        .then(() => {
          this.$router.replace({
            name: "organizations/list"
          });
        })
        .catch(err => (this.error = err));
    }
  }
};
</script>

<style></style>
